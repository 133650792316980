import React from "react"

import styles from "./NaviagationBar.module.scss";

export default class NaviationBar extends React.Component {

  render() {
    return <nav className={styles.NavigationBar}>
      <div className={styles.NavigationBar_Logo}><a href="/">Ben</a></div>

      <ul className={styles.NavigationBar_Naviation}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/benjamin-macdonald">
          <li>Hire Me</li>
        </a>
      </ul>
    </nav>
  }
}
