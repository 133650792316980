import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import NavigationBar from "./NavigationBar";

import "./layout.scss"

class LayoutView extends React.Component {

  render() {
    return <div className={"site"}>
      <NavigationBar />
      {this.props.children}
    </div>
  }
}


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <LayoutView data={data}>
        {children}
      </LayoutView>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
